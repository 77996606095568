import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useUser } from "../../contexts/UserProvider";
import { useApi } from "../../contexts/ApiProvider";
import { useFlash } from "../../contexts/FlashProvider";

export default function CourseEnrollPage() {
  const [isSubmit, setIsSubmit] = useState(false);
  const [isEnrolled, setIsEnrolled] = useState(false);

  const api = useApi();
  const { user } = useUser();
  const flash = useFlash();
  const id = useParams().id;

  const handleEnrollment = async () => {
    setIsSubmit(true);
    const response = await api.post(`/api/orders`, {
      user: user._id,
      course: id,
    });
    if (!response.error) {
      flash(`Enregistrement effectué`, `success`);
      setIsSubmit(false);
    } else {
      flash(response.error.message, "danger");
      setIsSubmit(false);
    }
  };

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/orders/${user?._id}/${id}`);
      if (!response.error && response.length > 0) {
        setIsEnrolled(true);
      }
    })();
  }, [api, user, id]);

  return (
    <section>
      <div className="container-lg">
        <header>
          <h1>Termes et conditions</h1>
        </header>
        <div className="row">
          <div className="col-8">
            <h2>1. Inscription et Admissibilité</h2>
            <p>
              Pour vous inscrire au Cours, vous devez remplir le formulaire
              d'inscription en fournissant des informations précises et
              véridiques.
            </p>
            <p>
              Vous devez répondre aux critères d'admissibilité spécifiés pour
              participer au Cours.
            </p>
            <h2>Frais et Paiement :</h2>
            <p>
              Des frais d'inscription sont exigibles pour participer au Cours.
              Ces frais sont indiqués dans les informations du Cours.
            </p>
            <p>
              Le paiement des frais d'inscription doit être effectué en totalité
              avant le début du Cours.
            </p>
            <p>
              Les frais d'inscription ne sont pas remboursables, sauf en cas
              d'annulation du Cours par l'Institution/Organisation.
            </p>
            <h2>Accès au Cours :</h2>
            <p>
              Une fois que vous avez effectué le paiement des frais
              d'inscription, vous recevrez des informations sur la manière
              d'accéder au matériel du Cours et à la plateforme d'apprentissage,
              le cas échéant.
            </p>
            <p>
              Vous êtes responsable de maintenir la confidentialité de vos
              informations d'accès au Cours et de ne pas les partager avec des
              tiers.
            </p>
            <h2>Contenu du Cours :</h2>
            <p>
              Tout le contenu du Cours, y compris les documents, les vidéos, les
              exercices et les évaluations, est protégé par des droits d'auteur
              et est destiné à votre usage personnel uniquement.
            </p>
            <p>
              Vous n'êtes pas autorisé(e) à reproduire, distribuer ou partager
              le contenu du Cours sans l'autorisation expresse de
              l'Institution/Organisation.
            </p>
            <h2>Participation et Conduite :</h2>
            <p>
              Vous êtes tenu(e) de participer activement au Cours, de soumettre
              les devoirs dans les délais impartis et de respecter les règles de
              conduite appropriées dans les interactions en ligne liées au
              Cours.
            </p>
            <p>
              Tout comportement inapproprié, tel que le harcèlement, la
              diffamation ou la violation des droits d'autrui, ne sera pas
              toléré et peut entraîner la suspension ou l'expulsion du Cours.
            </p>
            <h2>Annulation et Remboursement :</h2>
            <p>
              L'Institution/Organisation se réserve le droit d'annuler le Cours
              en raison de circonstances imprévues. En cas d'annulation, vous
              serez remboursé(e) intégralement.
            </p>
            <p>
              En cas de demande d'annulation de votre part avant le début du
              Cours, les frais d'inscription ne seront pas remboursés.
            </p>
            <h2>Modification des Termes et Conditions :</h2>
            <p>
              L'Institution/Organisation se réserve le droit de modifier ces
              Conditions à tout moment. Les modifications prendront effet dès
              leur publication sur le site web du Cours.
            </p>
            <p>
              Il vous incombe de consulter régulièrement les Conditions pour
              prendre connaissance des éventuelles modifications.
            </p>
          </div>
          <div className="col-4">
            <div className="px-4">
              <p className="mb-4">
                En vous inscrivant au Cours, vous reconnaissez avoir lu, compris
                et accepté l'ensemble des Termes et Conditions énoncés
                ci-dessus. Si vous ne comprenez pas ou n'acceptez pas ces
                Conditions, veuillez ne pas vous inscrire au Cours.
              </p>
              <br />
              {isEnrolled ? (
                <h5 className="text-danger">Vous êtes déjà inscrit</h5>
              ) : (
                <button
                  onClick={handleEnrollment}
                  className="btn btn-primary w-100 mb-4"
                  disabled={isSubmit}
                >
                  VALIDER MON INSCRIPTION
                </button>
              )}
              <br />
              <Link className="btn btn-outline-primary w-100" to={`/courses`}>
                Retour
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
