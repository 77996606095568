import React, { useEffect, useState } from "react";

import Navbar from "../../components/Navbar";
import TestimonialSection from "../../components/TestimonialSection";
import Footer from "../../components/Footer";
import CourseDetailsHead from "../../components/CourseDetailsHead";
import CourseDetailsBody from "../../components/CourseDetailsBody";
import CourseDetailsFooter from "../../components/CourseDetailsFooter";
import { useApi } from "../../contexts/ApiProvider";
import { useParams } from "react-router";
import Spinner from "../../components/Spinner";

export default function CourseDetailsPage() {
  const [course, setCourse] = useState();

  const api = useApi();
  const id = useParams().id;

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/items/${id}`);
      if (!response.error) {
        setCourse(response);
      }
    })();
  }, [api]);

  return (
    <div>
      {course === undefined ? (
        <Spinner />
      ) : (
        <>
          <CourseDetailsHead course={course} />
          <CourseDetailsBody course={course} />
          <CourseDetailsFooter course={course} />
          <TestimonialSection />
        </>
      )}
    </div>
  );
}
