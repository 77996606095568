import React from "react";

export default function CtaSection() {
  return (
    <section id="intro">
      <div class="container-lg">
        <div class="row g-4 justify-content-between align-items-center">
          <div class="col-md-5 text-center text-md-start">
            <h1>
              <div class="display-6 fw-bold">
                Prêt à revitaliser votre parcours professionnel ?
              </div>
            </h1>
            <p class="lead my-4 text-muted d-none d-md-block">
              Actualisez vos connaissances, cultivez de nouvelles compétences,
              obtenez des certifications professionnelles... Quels que soient
              vos objectifs professionnels, nous sommes là pour vous guider et
              vous soutenir. Embrassez un métier prometteur avec l'excellence de
              l'apprentissage en ligne.
            </p>
            <a href="#pricing" class="btn btn-primary btn-lg">
              Démarrer mon inscription
            </a>
          </div>
          <div class="col-md-5 text-center d-none d-md-block">
            <span
              class="tt"
              data-bs-placement="bottom"
              title="Net Ninja Pro book cover"
            ></span>
            <video
              width="320"
              height="240"
              controls
              class="object-fit-contain"
              autoPlay
            >
              <source
                src="/assets/1472584_Education_People_3840x2160.mov"
                type="video/mov"
              />
            </video>
          </div>
        </div>
      </div>
    </section>
  );
}
