import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import HomePage from "./pages/HomePage";
import CourseListPage from "./pages/courses/CourseListPage";
import CourseDetailsPage from "./pages/courses/CourseDetailsPage";
import CourseEnrollPage from "./pages/courses/CourseEnrollPage";
import AppLayout from "./layouts/AppLayout";
import { useUser } from "./contexts/UserProvider";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import PrivateRoute from "./components/PrivateRoute";
import LogoutPage from "./pages/LogoutPage";
import ProfilePage from "./pages/ProfilePage";

export default function App() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/logout" element={<LogoutPage />} />
        <Route path="" element={<AppLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/courses" element={<CourseListPage />} />
          <Route path="/courses/:id" element={<CourseDetailsPage />} />
        </Route>
        <Route
          path="*"
          element={
            <PrivateRoute>
              <Routes>
                <Route path="" element={<AppLayout />}>
                  <Route
                    path="/courses/:id/enroll"
                    element={<CourseEnrollPage />}
                  />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="*" element={<Navigate to="/" />} />
                </Route>
              </Routes>
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
}

function RequireAuth({ children }) {
  let { user } = useUser();
  let location = useLocation();

  console.log(user);

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
