import React from "react";
import ImageSlider from "../components/ImageSlider";
import FeatureSection from "../components/FeatureSection";
import FeatureListSection from "../components/FeatureListSection";
import CtaSection from "../components/CtaSection";
import TestimonialSection from "../components/TestimonialSection";

export default function HomePage() {
  return (
    <>
      <ImageSlider />
      <FeatureSection />
      <FeatureListSection />
      <CtaSection />
      <TestimonialSection />
    </>
  );
}
