import React from "react";
import { Link } from "react-router-dom";

export default function CourseDetailsHead({ course }) {
  return (
    <section>
      <div className="container-lg">
        <div className="row">
          <div className="col-8">
            <Link to={`/courses`}> &larr; Retour</Link>
            <h1>{course?.title}</h1>
            <p>{course?.description}</p>
            <div className="d-flex align-items-center gap-4">
              <span>
                <i class="bi bi-calendar-date-fill"></i> Date : {course?.date}
              </span>
              <span>
                <i class="bi bi-alarm-fill"></i> Durée : {course?.duration}
              </span>
              <span>
                <i class="bi bi-geo-alt-fill"></i>Lieu : {course?.place}
              </span>
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex align-items-center gap-2">
              <h2>{course?.price} F.CFA</h2>
              <h3>
                <del>{course?.discountPrice} F.CFA</del>
              </h3>
            </div>
            <Link
              className="btn btn-primary w-100"
              to={`/courses/${course?._id}/enroll`}
            >
              S'inscrire
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
