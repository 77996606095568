import React from "react";
import CourseList from "../../components/CourseList";
import TestimonialSection from "../../components/TestimonialSection";

export default function CourseListPage() {
  return (
    <>
      <CourseList />
      <TestimonialSection />
    </>
  );
}
