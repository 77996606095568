import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";

import "./styles/app.scss";

import FlashProvider from "./contexts/FlashProvider";
import ApiProvider from "./contexts/ApiProvider";
import UserProvider from "./contexts/UserProvider";
import FlashMessage from "./components/FlashMessage";
import ModalProvider from "./contexts/ModalProvider";

ReactDOM.render(
  <BrowserRouter>
    <FlashProvider>
      <ApiProvider>
        <UserProvider>
          <ModalProvider>
            <FlashMessage />
            <App />
          </ModalProvider>
        </UserProvider>
      </ApiProvider>
    </FlashProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
