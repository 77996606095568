import React, { useEffect, useState } from "react";
import { useUser } from "../contexts/UserProvider";
import Avatar from "../components/Avatar";
import Spinner from "../components/Spinner";
import { useApi } from "../contexts/ApiProvider";
import { Image } from "../components/Image";

export default function ProfilePage() {
  const [orders, setOrders] = useState();

  const { user } = useUser();
  const api = useApi();

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/orders/me`);
      if (!response.error) {
        setOrders(response);
      }
    })();
  }, []);

  return (
    <>
      {user === undefined ? (
        <Spinner />
      ) : (
        <section>
          <div className="container-lg">
            <div className="d-flex gap-4 mb-4 align-items-center">
              <div className="">
                <Avatar
                  size={100}
                  img={user?.profilePicture}
                  text={user?.firstName}
                />
              </div>
              <div>
                <h1>{user.firstName + " " + user.lastName}</h1>
              </div>
            </div>
            <hr />
            {orders === undefined ? (
              <Spinner />
            ) : (
              <div className="m-4">
                <div className="my-4">
                  <h4>Mes Cours</h4>
                </div>
                <div>
                  {orders?.map((order, index) => (
                    <div className="d-flex gap-4" key={index}>
                      <div>
                        <Image
                          img={order.course.images[0]}
                          alt={order.course.title}
                          size={`150px`}
                        />
                      </div>
                      <div>
                        <h5>{order.course.title}</h5>
                        <p>
                          {order.course.description.length > 100
                            ? `${order.course.description.substr(0, 100)}...`
                            : order.course.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>
      )}
    </>
  );
}
