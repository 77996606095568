import React from "react";

export default function TestimonialSection() {
  return (
    <section id="reviews" class="bg-light">
      <div class="container-lg text-center">
        <div
          id="carouselExample"
          class="carousel slide container"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="10000">
              <div class="row justify-content-center my-5 align-items-center text-center">
                <div class="pb-2">
                  <i class="bi bi-star-fill text-dark"></i>
                  <i class="bi bi-star-fill text-dark"></i>
                  <i class="bi bi-star-fill text-dark"></i>
                  <i class="bi bi-star-fill text-dark"></i>
                  <i class="bi bi-star-half text-dark"></i>
                </div>
                <h5 class="mb-1 fw-bold">
                  A must-buy for every aspiring web dev
                </h5>
                <p class="mb-1 lead">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Tenetur error veniam sit expedita est illo maiores neque quos
                  nesciunt, reprehenderit autem odio commodi labore praesentium
                  voluptate repellat in id quisquam.
                </p>
                <small>Review by Mario</small>
              </div>
            </div>
            <div class="carousel-item" data-bs-interval="30000">
              <div class="row justify-content-center my-5">
                <div class="pb-2">
                  <i class="bi bi-star-fill text-dark"></i>
                  <i class="bi bi-star-fill text-dark"></i>
                  <i class="bi bi-star-fill text-dark"></i>
                  <i class="bi bi-star-fill text-dark"></i>
                  <i class="bi bi-star-half text-dark"></i>
                </div>
                <h5 class="mb-1 fw-bold">
                  A must-buy for every aspiring web dev
                </h5>
                <p class="mb-1 lead">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Tenetur error veniam sit expedita est illo maiores neque quos
                  nesciunt, reprehenderit autem odio commodi labore praesentium
                  voluptate repellat in id quisquam.
                </p>
                <small>Review by Kolo</small>
              </div>
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExample"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </section>
  );
}
