import React from "react";

export default function FeatureListSection() {
  return (
    <section id="pricing" class="mt-5 bg-light">
      <div class="container-lg">
        <div class="text-center">
          <h2 class="fw-bold">Connaissance. Capacité. Aptitude.</h2>
          <p class="lead text-muted">
            Explorez une approche innovante de l'apprentissage : 10 % de
            théorie, enrichie par 90 % de pratique.
          </p>
        </div>

        <div class="row my-5 align-items-center justify-content-md-between justify-content-center">
          <div class="col-8 col-lg-4 col-xl-3">
            <div class="card border-0 bg-light">
              <img
                style={{
                  width: "250px",
                  height: "250px",
                  objectFit: "cover",
                  margin: "auto",
                }}
                class="card-img-top rounded-circle border"
                src={process.env.PUBLIC_URL + "/assets/images/learn-online.jpg"}
                alt=""
              />
              <div class="card-body py-4 text-center text-md-start">
                <h4 class="card-title">Acquérez des connaissances</h4>
                <p class="lead card-subtitle d-none d-md-block">
                  Bénéficiez de formations. Quelques soit votre niveau scolaire,
                  nous avons une formation taillée pour vous. Améliorez-vous
                  grâce à l'acquisition de connaissances.
                </p>
              </div>
            </div>
          </div>

          <div class="col-8 col-lg-4 col-xl-3">
            <div class="card border-0 bg-light">
              <img
                style={{
                  width: "250px",
                  height: "250px",
                  objectFit: "cover",
                  margin: "auto",
                }}
                class="card-img-top rounded-circle border"
                src={process.env.PUBLIC_URL + "/assets/images/mentor.jpg"}
                alt=""
              />
              <div class="card-body py-4 text-center text-md-start">
                <h4 class="card-title">Quelqu'un pour vous soutenir.</h4>
                <p class="lead card-subtitle d-none d-md-block">
                  Recevez chaque semaine les conseils d'un professionnel
                  chevronné qui vous assiste dans votre progression tout au long
                  de votre apprentissage.
                </p>
              </div>
            </div>
          </div>

          <div class="col-8 col-lg-4 col-xl-3">
            <div class="card border-0 bg-light">
              <img
                style={{
                  width: "250px",
                  height: "250px",
                  objectFit: "cover",
                  margin: "auto",
                }}
                // style="width: 250px; height: 250px; object-fit: cover; margin: auto;"
                class="card-img-top rounded-circle border"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/kids-chatting-online.jpg"
                }
                alt=""
              />
              <div class="card-body py-4 text-center text-md-start">
                <h4 class="card-title">
                  Êtes-vous prêt à insuffler une nouvelle dynamique à votre
                  carrière ?
                </h4>
                <p class="lead card-subtitle d-none d-md-block">
                  Menez à bien des projets pratiques basés sur des scénarios
                  professionnels, directement applicables en milieu
                  professionnel.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
