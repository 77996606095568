import React from "react";

export default function Footer() {
  return (
    <footer className="text-center text-lg-start bg-white text-muted">
      <section>
        <div class="container-lg p-4 pb-0">
          <form action="">
            <div class="row d-flex justify-content-center">
              <div class="col-auto">
                <p class="pt-2">
                  <strong>Sign up for our newsletter</strong>
                </p>
              </div>
              <div class="col-md-5 col-12">
                <div class="form-outline mb-4">
                  <input
                    type="email"
                    id="form5Example27"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-auto">
                <button type="submit" class="btn btn-primary mb-4">
                  Subscribe
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section className="">
        <div className="container-lg text-center text-md-start mt-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">
                <i className="fas fa-gem text-secondary"></i>BOUCHRA CONSULTING
              </h6>
              <p>
                Here you can use rows and columns to organize your footer
                content. Lorem ipsum dolor sit amet, consectetur adipisicing
                elit.
              </p>
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">FORMATIONS</h6>
              <p>
                <a href="#/" className="text-reset">
                  Angular
                </a>
              </p>
              <p>
                <a href="#/" className="text-reset">
                  React
                </a>
              </p>
              <p>
                <a href="#/" className="text-reset">
                  Vue
                </a>
              </p>
              <p>
                <a href="#/" className="text-reset">
                  Laravel
                </a>
              </p>
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">LIENS UTILES</h6>
              <p>
                <a href="#/" className="text-reset">
                  Pricing
                </a>
              </p>
              <p>
                <a href="#/" className="text-reset">
                  Settings
                </a>
              </p>
              <p>
                <a href="#/" className="text-reset">
                  Orders
                </a>
              </p>
              <p>
                <a href="#/" className="text-reset">
                  Help
                </a>
              </p>
            </div>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">CONTACTS</h6>
              <p>
                <i className="bi bi-house me-3 text-secondary"></i> New York, NY
                10012, US
              </p>
              <p>
                <i className="bi bi-envelope me-3 text-secondary"></i>
                info@example.com
              </p>
              <p>
                <i className="bi bi-phone me-3 text-secondary"></i> + 01 234 567
                88
              </p>
              <p>
                <i className="bi bi-printer me-3 text-secondary"></i> + 01 234
                567 89
              </p>
            </div>
          </div>
        </div>
      </section>

      <section class="mb-4">
        <div class="container-lg text-center">
          <a class="btn text-primary btn-floating m-1" href="#!" role="button">
            <i class="bi bi-bicebook-f"></i>
          </a>

          <a
            class="btn text-primary btn-floating m-1 fs-2"
            href="#!"
            role="button"
          >
            <i class="bi bi-twitter"></i>
          </a>

          <a
            class="btn text-primary btn-floating m-1 fs-2"
            href="#!"
            role="button"
          >
            <i class="bi bi-google"></i>
          </a>

          <a
            class="btn text-primary btn-floating m-1 fs-2"
            href="#!"
            role="button"
          >
            <i class="bi bi-instagram"></i>
          </a>

          <a
            class="btn text-primary btn-floating m-1 fs-2"
            href="#!"
            role="button"
          >
            <i class="bi bi-linkedin"></i>
          </a>
          <a
            class="btn text-primary btn-floating m-1 fs-2"
            href="#!"
            role="button"
          >
            <i class="bi bi-github"></i>
          </a>
        </div>
      </section>

      <div className="text-center p-4 bg-light">
        © 2021 Copyright:
        <a className="text-reset fw-bold" href="/">
          Bouchra - Consulting
        </a>
      </div>
    </footer>
  );
}
