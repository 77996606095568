import React from "react";
import { Link } from "react-router-dom";

import { useUser } from "../contexts/UserProvider";

export default function Navbar() {
  const { user } = useUser();

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
      <div className="container">
        <a href="/" className="navbar-brand d-flex align-items-center" href="#">
          <img
            src={`${process.env.PUBLIC_URL}/logo.png`}
            alt="Logo"
            width="60"
            height="60"
            class="d-inline-block align-text-top me-2"
          />
          <div>
            <h3 className="mb-0 fw-bold">Bouchra Consulting</h3>
            <small className="mb-0 d-lg-block d-none">
              Formation-Conseils-Etudes
            </small>
          </div>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li class="nav-item position-static dropdown">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Formations
              </a>
              <div class="dropdown-menu w-100 dropdown-megamenu">
                <section className="container">
                  <div className="row">
                    <div className="col-auto col-lg-4">
                      <div className="d-flex">
                        <div className="me-4">
                          <i class="bi bi-award-fill fs-1 text-primary"></i>
                        </div>
                        <div>
                          <h5 className="text-primary">Formations continues</h5>
                          <p>
                            Les formations continues permettent aux
                            professionnels de rester compétitifs sur le marché
                            du travail en élargissant leurs compétences et en se
                            tenant informés des dernières avancées
                          </p>
                          <Link
                            to={`/courses`}
                            className="btn text-primary ps-0"
                          >
                            Continuer à lire &rarr;
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-auto col-lg-4">
                      <div className="d-flex">
                        <div className="me-4">
                          <i class="bi bi-mortarboard-fill fs-1 text-primary"></i>
                        </div>
                        <div>
                          <h5 className="text-primary">
                            Formations Qualifiantes
                          </h5>
                          <p>
                            Les formations qualifiantes sont conçues pour
                            préparer les participants à des emplois spécifiques
                            et à des rôles professionnels. Elles offrent des
                            compétences pratiques et techniques applicables dans
                            le monde du
                          </p>
                          <Link
                            to={`/courses`}
                            className="btn text-primary ps-0"
                          >
                            Continuer à lire &rarr;
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </li>

            <li className="nav-item mx-4">
              <a className="nav-link active" aria-current="page" href="#">
                Qui nous sommes
              </a>
            </li>

            <li className="nav-item mx-4">
              <a className="nav-link" href="#">
                Contactez-nous
              </a>
            </li>
          </ul>
          <div className="d-flex" role="search">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              {user ? (
                <li class="nav-item dropdown">
                  <a
                    class="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {user.firstName + " " + user.lastName}
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <Link class="dropdown-item" to={`/profile`}>
                        Mon profile
                      </Link>
                    </li>
                    <li>
                      <hr class="dropdown-divider" />
                    </li>
                    <li>
                      <Link class="dropdown-item" to={`/logout`}>
                        Déconnexion
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : (
                <li class="nav-item">
                  <Link class="nav-link" to={`/login`}>
                    Connectez-vous
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}
