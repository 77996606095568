import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../contexts/ApiProvider";
import { Image } from "./Image";

export default function ImageSlider() {
  const [featured, setFeatured] = useState();

  const api = useApi();

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/items`);
      if (!response.error) {
        setFeatured(response);
      }
    })();
  }, []);

  return (
    <section className="bg-light">
      <div
        id="carouselExample"
        class="carousel slide container"
        data-bs-ride="carousel"
      >
        <div class="carousel-inner">
          {featured?.map((feat, index) => (
            <div
              class={`carousel-item ${index === 0 ? "active" : ""}`}
              data-bs-interval="10000"
              key={index}
            >
              <div className="row justify-content-between align-items-center">
                <div className="col-md-8">
                  <h2 className="display-4 fw-bold mb-2">{feat.title}</h2>
                  <p className="mb-4 lead">{feat.description}</p>
                  <div className="d-flex gap-2">
                    <Link
                      className="btn btn-primary btn-lg"
                      to={`/courses/${feat._id}/enroll`}
                    >
                      M'inscrire
                    </Link>
                    <Link
                      to={`/courses`}
                      className="btn btn-outline-primary btn-lg d-none d-md-block"
                    >
                      Explorer
                    </Link>
                  </div>
                </div>
                <div className="col-md-4 d-none d-md-block">
                  <Image
                    image={feat.images[0].name}
                    alt={feat.title}
                    width={`100%`}
                    height={`100%`}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          class="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="prev"
        >
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button
          class="carousel-control-next"
          type="button"
          data-bs-target="#carouselExample"
          data-bs-slide="next"
        >
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </section>
  );
}
