import React from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../contexts/UserProvider";
import { useFlash } from "../contexts/FlashProvider";

export default function LogoutPage() {
  const flash = useFlash();
  const { logout } = useUser();
  const navigate = useNavigate();

  const handleLogout = async () => {
    const response = await logout();
    if (!response.error) {
      flash("Vous êtes deconnectez", "danger");
      navigate("/");
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-4 offset-sm-4 mt-5">
          <div className="text-center mb-4">
            <img src="../../images/logo.png" alt="" width="100px" />
          </div>
          <div className="card p-5 mb-4">
            <h5 className="text-center mb-4">
              Êtes-vous certain de vouloir vous déconnecter?
            </h5>
            <button
              className="btn btn-light border-1 w-100"
              onClick={handleLogout}
            >
              Déconnexion
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
