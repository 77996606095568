import React from "react";

export const Image = ({ image, alt, width, height }) => {
  return (
    <img
      src={
        image
          ? `${process.env.REACT_APP_BACKEND_URL}/image/${image}`
          : `${process.env.PUBLIC_URL}/assets/images/default-placeholder.png`
      }
      alt={alt || "Avatar"}
      style={{
        width: width || 50,
        height: height || 50,
        borderRadius: "5%",
        objectFit: "cover",
      }}
    />
  );
};
