import React from "react";
import { Link } from "react-router-dom";

export default function FeatureSection() {
  return (
    <section className="container-lg">
      <div className="row justify-content-between align-items-center">
        <div className="col-md-6">
          <img
            className="img-fluid d-block w-100"
            src={process.env.PUBLIC_URL + "/assets/images/4110980.jpg"}
          />
        </div>
        <div className="col-md-6">
          <span className="lead text-muted">Solutions</span>
          <h1 className="fw-bold">
            Libérez votre potentiel avec nos services de formation
          </h1>
          <p className="lead">
            Nos services de formation fournissent les outils et les conseils
            nécessaires pour aider les professionnels et les étudiants à
            exceller dans les domaines de leur choix. avec notre expertise et
            notre soutien, vous pouvez atteindre vos objectifs et atteindre de
            nouveaux sommets.
          </p>
          <div className="d-md-flex my-5">
            <div className="col-md-6">
              <h3 className="fw-bold">Notre Expertise</h3>
              <p className="lead">
                Bénéficiez de nos vastes connaissances et de notre expérience
                pour améliorer vos compétences et connaissances
              </p>
            </div>
            <div className="col-md-6">
              <h3 className="fw-bold">Opportunités</h3>
              <p className="lead">
                Saisissez l'opportunité d'apprendre des experts de l'industrie
                et d'acquérir une avantage concurrentiel.
              </p>
            </div>
          </div>

          <div className="d-flex gap-3">
            <Link className="btn btn-primary btn-lg">
              Demmarrer mon enregistrement
            </Link>
            <Link className="btn btn-outline-primary btn-lg d-none d-md-block">
              Découvrer les formations
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
