import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../contexts/ApiProvider";
import Avatar from "./Avatar";
import { Image } from "./Image";
import CourseItem from "./CourseItem";

export default function CourseList() {
  const [courses, setCourses] = useState();

  const api = useApi();

  useEffect(() => {
    (async () => {
      const response = await api.get(`/api/items`);
      if (!response.error) {
        setCourses(response);
      }
    })();
  }, []);

  return (
    <section>
      <div className="container-lg">
        <h1>Toutes nos formations</h1>
        <div>
          {courses?.map((course, index) => (
            <CourseItem key={index} course={course} />
          ))}
        </div>
      </div>
    </section>
  );
}
