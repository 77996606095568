import React from "react";
import DOMPurify from "dompurify";

export default function CourseDetailsBody({ course }) {
  return (
    <section>
      <div className="container-lg">
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(course?.content),
          }}
        />
      </div>
    </section>
  );
}
