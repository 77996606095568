import React from "react";
import { Link } from "react-router-dom";
import { Image } from "./Image";

export default function CourseItem({ course }) {
  return (
    <Link
      to={`/courses/${course._id}`}
      className="d-flex align-items-start my-5"
      style={{ textDecoration: "none" }}
    >
      <div className="d-flex gap-4">
        <div>
          <Image
            image={course.images[0].name}
            alt={course.title}
            width={`200px`}
            height={`150px`}
          />
        </div>
        <div>
          <span className="text-light badge bg-warning mb-1">
            {course.category.name}
          </span>
          <h4 className="text-dark">{course.title}</h4>
          <p className="lead text-dark">
            {course.description.length > 100
              ? `${course.description.substr(0, 100)}...`
              : course.description}
          </p>
        </div>
      </div>
    </Link>
  );
}
